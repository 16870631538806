<template>
    <div class="page1">
        <EForm
                :formColumns="formColumns"
                :rowSize="4"
                :optionsBtn="true"
                :actionBtn="false"
                :formData="searchForm"
                ref="form"
                @onSearch="onSearch"
                :searchFlag="true"
                :exportShow="false"
        >
        </EForm>
        <!--    <el-button type="primary" @click="dialogVisible = true" size="small">增加</el-button>-->
        <!--     <EButton type="primary" @click="dialogVisible = true">增加</EButton>-->
        <ETable
                :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="true"
                :count="count"
                @changePage="changePage"
                @changeSize="changeSize"
                :page="searchForm.current"
                :page_size="searchForm.size"
        >
            <div slot="isNeedCarry" slot-scope="scope">
                {{scope.data.isNeedCarry==1?'是':'否'}}
            </div>
            <div slot="status" slot-scope="scope">
                {{scope.data.status==1?'是':'否'}}
            </div>
            <el-table-column fixed="right" label="操作" width="180px">
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="saveCar(scope.row.id)" v-if="scope.row.status==0"> 完成</EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
    </div>
</template>

<script>
  import ETable from '@/components/ETable';
  import Http from '@/service/http';
  import EDialog from '@/components/EDialog';
  import EButton from '@/components/EButton';
  import EForm from '@/components/EForm';
  import {identity_type, status} from '@/assets/js/config';
  import vxRule from '@/assets/js/formValidate';
  import {otherMixin} from '@/components/mixin';
  import {mapGetters} from 'vuex'

  export default {
    name: 'shopInfo',
    mixins: [otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '订单编号',
            prop: 'callOrderNum',
            width:155
          },
          {
            label: '叫车时间',
            prop: 'createTime',
            width:155
          },
          {
            label: '联系人',
            prop: 'custName',
          },
          {
            label: '联系电话',
            prop: 'custPhone',
            width:155
          },
          {
            label: '种类',
            prop: 'carryType',
          },
          {
            label: '预计用时',
            prop: 'useCarTime',
          },
          {
            label: '是否需要搬运',
            prop: 'isNeedCarry',
            type:'slot'
          }, {
            label: '搬运数量',
            prop: 'carryCount',
          }, {
            label: '是否已处理',
            prop: 'status',
            type:'slot'
          },

        ],
        formColumns: [
          {
            title: '联系人',
            type: 'text',
            property: 'custName',
            show: true,
          },

          {
            title: '联系电话 ',
            type: 'text',
            property: 'custPhone',
            show: true,
          },
          {
            title: '叫车起始时间',
            type: 'datePicker',
            property: 'createTimeStart',
            show: true,
          },
          {
            title: '叫车截止时间',
            type: 'datePicker',
            property: 'createTimeEnd',
            show: true,
          },
          {
            title: '种类',
            type: 'select',
            property: 'carryType',
            show: true,
            options: [],
          },
{
            title: '是否已处理',
            type: 'select',
            property: 'status',
            show: true,
            options: [ {
              label: '全部',
              value: '',
            },
              {
                label: '是',
                value: 1,
              },
              {
                label: '否',
                value: 0,
              },],
          },

        ],
        tableData: [],
        searchForm: {
          custName: null,
          custPhone: null,
          createTimeStart: null,
          createTimeEnd: null,
          carryType: null,
          status:0,
          current: 1,
          size: 10,
        },
        count: null,
        exportData: {},

      };
    },
    watch: {},
    created() {
      this.getData();
      this.queryCarryType()
    },
    components: {ETable, EDialog, EButton, EForm},
    computed: {
      ...mapGetters(['buttonList']),
    },
    beforeMount() {
    },
    methods: {
      onSearch() {
        this.searchForm.current = 1;
        this.getData();
      },
      changePage(current) {
        this.searchForm.current = current;
        this.getData();
      },
      changeSize(size) {
        this.searchForm.size = size;
        this.getData();
      },

      async getData() {
        let res = await Http.carCallList(this.searchForm);
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.count = res.data.total;
        }
      },
      async queryCarryType() {
        let res = await Http.queryCarryType();
        if (res.code == 200) {
          this.formColumns[4].options = res.data
        }
      },
      async saveCar(id){
        let res = await Http.saveCarCall({
          id,
          status:1
        });
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
    .ws {
        width: 100%;
    }
</style>
